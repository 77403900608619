import React from "react";
import { useSelector } from "react-redux";
import { Box, FormHelperText } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { selectCurrentAccount, useGetAccountSubscriptionsQuery } from "fond/api";
import { ExtendedPermission, SubscriptionStatus } from "fond/types";

import { ErrorOutline, InfoHelperText, PermissionUserTableCell } from "./PermissionsField.styles";

interface IProps {
  permission: ExtendedPermission;
  submitError?: any;
}

const PermissionUserCell: React.FC<IProps> = ({ permission, submitError }) => {
  const currentAccount = useSelector(selectCurrentAccount);
  const { data: subscriptions } = useGetAccountSubscriptionsQuery(currentAccount?.ID ?? skipToken);
  const allExpired = subscriptions?.every((subscription) => subscription.Status === SubscriptionStatus.Expired);

  return (
    <PermissionUserTableCell data-testid="share-permission-user-id">
      <span>{permission.IdentityType === "user" ? permission.Identity.Email : permission.Identity.Name}</span>
      {permission.Guest && permission.Downgraded && (
        <Box display="flex" alignItems="start" data-testid="guest-permission-limit-notice">
          <ErrorOutline color="primary" />
          <InfoHelperText>This user is not part of this account and cannot be granted permissions higher than collaborator</InfoHelperText>
        </Box>
      )}
      {typeof permission.License !== "undefined" &&
        (!permission.License || allExpired) &&
        (permission.Level === "manage" || permission.Level === "write") && (
          <Box display="flex" alignItems="start" data-testid="no-license-notice">
            <ErrorOutline color="error" />
            <InfoHelperText sx={{ color: (theme) => theme.palette.biarri.primary.red }}>
              This user&nbsp;
              {!permission.License ? "does not have a license." : "has an expired license."}
              &nbsp;A valid license will be required to create and manage content.
            </InfoHelperText>
          </Box>
        )}
      {submitError && (
        <FormHelperText error data-testid="permission-helper-error">
          {`${submitError}`}
        </FormHelperText>
      )}
    </PermissionUserTableCell>
  );
};

export default PermissionUserCell;
