import { dialogContentClasses, IconButton, Modal, styled } from "@mui/material";

export const FilesViewerModal = styled(Modal)`
  & .${dialogContentClasses.root} {
    padding: 0;
  }

  .swiper-slide {
    box-sizing: border-box;
    padding: 10px 20px;
  }

  .swiper-pagination {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: white;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 10;
    border: 1px solid ${({ theme }) => theme.palette.divider};
  }
`;

export const PrevSlideButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  font-size: 2.5rem;
  z-index: 10;
  background-color: white;
  box-shadow: ${({ theme }) => theme.shadows[3]};
  transition: all ${({ theme }) => theme.transitions.duration.shorter}ms;

  svg {
    scale: 1;
    transition: scale ${({ theme }) => theme.transitions.duration.shorter}ms;
  }

  &[disabled] {
    background-color: ${({ theme }) => theme.palette.biarri.secondary.whiteSmoke};
    box-shadow: ${({ theme }) => theme.shadows[1]};

    svg {
      scale: 0.9;
    }
  }
`;

export const NextSlideButton = styled(PrevSlideButton)`
  left: unset;
  right: 10px;
  padding: 12px; // reset to default button padding
`;
