export const CITY_PLANNER_AREA_MAX = 10000;
export const CITY_PLANNER_MAX_SUBAREA_COUNT_FOR_DESIGN = 200;

// Define these here so we use the same image dimensions when requesting from fond service
// and adding the image to the map.
export const MAP_ICON_WIDTH = 20;
export const MAP_ICON_HEIGHT = 20;

export const STYLE_EDITOR_MIN_ZOOM = 0;
// The maximum zoom level for the layer. At zoom levels equal to or greater than the maxzoom, the layer will be hidden.
// Note: We set the maxzoom of the map to 22 so we require 23
export const STYLE_EDITOR_MAX_ZOOM = 23;

/*
 * While we set our own password policy, cognito has a hard limit
 * of min 8 chars. Therefore given that we can change our policy at
 * any time, the only thing we can definitely know about an
 * existing password is that it must be > 8 chars. We could not
 * check this at all and let cognito validate the current password,
 * however the error messages it gives are terrible so we should
 * guard against them if possible.
 */
export const MIN_PASSWORD_LENGTH = 8;
export const SEARCH_KEY = "search";
export const ACCOUNT_KEY = "account";
export const ACCOUNT_CONTEXT = "AccountContext";
export const DEMAND_MODEL_MODIFIED = "DemandModelModified";
export const RETURN_URL = "returnUrl";
export const FOLDER_ID = "folderId";
export const VERSION_ID = "versionId";
export const MULTI_PROJECT_ID = "multiProjectId";
export const MULTI_REPORT_FOLDER_ID = "multiReportFolderId";

export const INVITATION_ID_KEY = "invitation_id";
export const INVITATION_TOKEN_KEY = "token";

export const SOON_TO_EXPIRED_BANNER_KEY = "HIDE_SOON_TO_EXPIRE_BANNER";
export const EXPIRED_BANNER_KEY = "HIDE_EXPIRED_BANNER";
export const EXCEED_SUBSCRIPTION_QUOTA_KEY = "HIDE_EXCEED_SUBSCRIPTION_QUOTA_BANNER";
export const NO_LICENSE_ADMIN = "HIDE_NO_LICENSE_ADMIN_BANNER";
export const NO_LICENSE_MEMBER = "HIDE_NO_LICENSE_MEMBER_BANNER";

export const RECENT_PROJECTS_KEY = "recentprojects";
export const STARRED_PROJECTS_KEY = "starredprojects";

/**
 * Query key for product types
 * The product types relates to product categories
 *
 * @see ProductCategory `fond/types/stripe.ts`
 */
export const PRODUCT_CATEGORY_KEY = "product";
export const PRODUCT_SELECTION_KEY = "selected";
export const PURCHASE_TYPE_KEY = "type";

export const TOS_URL = "https://biarrinetworks.com/-biarri-legal-terms";
export const HELP_CENTER_URL = "https://fondhelp.biarrinetworks.com/";
export const PRIVACY_URL = "https://biarrinetworks.com/-biarri-privacy-policy-";

export const HOME_FOLDER_ID = "home";
export const HOME_FOLDER_LABEL = "Home";
export const HOME_FOLDER_OPTION = {
  ID: HOME_FOLDER_ID,
  Name: HOME_FOLDER_LABEL,
  Path: [],
};

export const NO_PHASE = "NO_PHASE";

/** The minimum tool versions required for projects used to generate a report */
export const REPORT_CTS_MIN_TOOL_VERSION = "7.6.0";
export const REPORT_BOM_MIN_TOOL_VERSION = "7.8.1";

/** Fond Viral Design constants */
export const FOND_VIRAL_DESIGN_PREFIX = "[FondSampleDesign]";
