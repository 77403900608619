import React from "react";
import { Content as Inner, LeftSidebar, Main, PageLayout, TopNavigation } from "@atlaskit/page-layout";
import { Box, Grid, Typography } from "@mui/material";

import { useUpdateDocTitle } from "fond/hooks/useUpdateDocTitle";
import { useUserManagementBanner } from "fond/hooks/useUpgradePlanner";
import { getImpersonatedUser } from "fond/impersonate";
import TopBar from "fond/topBar/TopBar";
import { Footer } from "fond/widgets";

import { IMPERSONATE_BANNER_HEIGHT, TOPBAR_HEIGHT, USERMANAGEMENT_BANNER_HEIGHT } from "./constants";

import { Content, Subtitle } from "./pageContent.styles";

export interface IProps {
  /**
   * The window title text content. Used to set the window title, if not provided the title will be used.
   */
  windowTitle?: string;
  /**
   * If of type string, the title text will be rendered as H1.
   */
  title: React.ReactNode;
  /**
   * The title text content.
   */
  subTitle?: string;
  /**
   * A collection of primary call to actions rendered to the right of the page heading
   */
  action?: React.ReactNode;
  /**
   * The main component of the page, such as project BOM or list of projects
   */
  mainContent: React.ReactNode;
  /**
   * Optional breadcrumbs section to be displayed under the page heading
   */
  breadcrumbs?: React.ReactNode;
  /**
   * Option navigation to be rendered with the left sidebar.
   */
  navigation?: React.ReactNode;
  /**
   * The `testId` prop is a unique string that appears as a data attribute `data-testid` in the rendered code, serving as a hook for automated tests.
   */
  testId?: string;
}

const PageContent: React.FC<IProps> = ({
  breadcrumbs = null,
  subTitle,
  title,
  action = null,
  mainContent,
  navigation = null,
  testId,
  windowTitle,
}: IProps) => {
  useUpdateDocTitle(windowTitle ?? title);
  const impersonate = getImpersonatedUser();
  const { showBanner: showUserManagementBanner } = useUserManagementBanner();
  /**
   * Determines the required height for the toolbar based on any conditional content
   * such as page alerts or the impersonating banner or the user management banner.
   */
  let topNavigationHeight = TOPBAR_HEIGHT;
  if (impersonate) topNavigationHeight += IMPERSONATE_BANNER_HEIGHT;
  if (showUserManagementBanner) topNavigationHeight += USERMANAGEMENT_BANNER_HEIGHT;

  return (
    <PageLayout testId={testId}>
      <TopNavigation isFixed height={topNavigationHeight}>
        <TopBar />
      </TopNavigation>
      <Inner testId="content">
        {navigation && <LeftSidebar testId="left-sidebar">{navigation}</LeftSidebar>}

        <Main>
          <Box display="flex" flexDirection="row" minHeight="100%">
            <Box flexGrow={1}>
              <Content maxWidth="lg">
                <Grid container item direction="column" flexGrow={1} position="relative">
                  <Grid container item direction="row" justifyContent="space-between" style={{ paddingBottom: 24 }}>
                    <Grid item>
                      {typeof title === "string" ? (
                        <Typography variant="h1" data-testid="page-title">
                          {title}
                        </Typography>
                      ) : (
                        title
                      )}
                      {subTitle && <Subtitle variant="subtitle2">{subTitle}</Subtitle>}
                      {breadcrumbs}
                    </Grid>
                    {action && <Grid item>{action}</Grid>}
                  </Grid>
                  <Grid item display="flex" flexDirection="column" flexGrow={1}>
                    {mainContent}
                  </Grid>
                </Grid>
                <Grid item style={{ paddingTop: 42 }}>
                  <Footer />
                </Grid>
              </Content>
            </Box>
          </Box>
        </Main>
      </Inner>
    </PageLayout>
  );
};

export default PageContent;
